import React from 'react';
import { useInView } from 'react-intersection-observer';
import app_store from '../images/app-store.png';
import google_play from '../images/google-play.png';
import styles from './Heroarea.module.css'
import color from '../constants/colors.json'

export default function Heroarea({title , description, image}) {
  const { ref, inView } = useInView({
    triggerOnce: true, 
  });
  return (
    <div className={`${styles.main} ${inView ? styles.inView : ''}` }ref={ref} style={{background:color.primary}}>
    <div className="hero-area-l-13 position-relative overflow-hidden" id="about">
      <div className={"container"}>
        <div className="row position-relative justify-content-center align-items-center">
          <div
            className="col-xl-6 col-lg-6 col-sm-9"
          >
            <div className={styles.iphoneimg}>
  <img src={image} alt="" width='350px' height='800px' className={styles.iphoneimg} />
</div>
          </div>
          <div
            className="offset-xl-1 col-xl-5 col-lg-6 col-md-8 col-sm-9"
            
          >
            <div className={styles.content}>
              <div className={styles.contentWrapper}>
                <div style={{ color: 'white'  ,textAlign : 'end'}}>
                  <h1>{title}</h1>
                  <p>
                    {description}
                  </p>
                </div>
                <div className={styles.app_btn}>
                  <a href="https://apps.apple.com/us/app/aneen-%D8%A3%D9%86%D9%8A%D9%86/id6455787159?uo=2">
                    <img src={app_store} alt="" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.targetube.aneen&pli=1">
                    <img src={google_play} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-shape-13"></div>
    </div>
    </div>
  );
}
