export const imageObject = {
  image: require("../images/content-img-1.jpg"),
  image1: require("../images/content-img-2.png"),
  image2: require("../images/content-img-3.png"),
  image3: require("../images/iphone-img.png.png"),
  image4: require("../images/aneenLogo.png"),
  twitter: require("../images/twitter.png"),
  facebook: require("../images/facebook.png"),
  instagram: require("../images/instagram.png"),
  youtube: require("../images/youtube.png"),
  tikTok: require("../images/tiktok.png"),
  threads : require("../images/threads.png"),
  linkedIn : require("../images/linkedin.png"),
  image5: require("../images/logox1.jpg"),
  image6: require("../images/logox2.jpg"),

  image7: require("../images/logox3.jpg"), 
   image7: require("../images/AneenWithBg.jpg"),


};
