import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyC3B1SRfW-CSepy4j1PzwP5a5wlDBuzyMk",
  authDomain: "aneen-9f883.firebaseapp.com",
  projectId: "aneen-9f883",
  storageBucket: "aneen-9f883.appspot.com",
  messagingSenderId: "237015747549",
  appId: "1:237015747549:web:4b1915719b68d82b038434",
  measurementId: "G-KZPMGB6VGT",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
