import React from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Contentright.module.css'
import colors from '../constants/colors.json'

export default function AboutUs({title, description,}) {
  
  const { ref, inView } = useInView({
    triggerOnce: true, 
  });
  return (
    
              <div className="row justify-content-center" style={{ backgroundColor: colors.primary }} >
                <div className="col-xl-9" >
                  <div className="row align-items-center justify-content-center justify-content-lg-start">
                    <div>
                      <br />
                      <br />
                      <div className={`${styles.contentrighttext} ${styles.contentWrapper} ${inView ? styles.inView : ''}`}ref={ref} >
                        {/* <h2 style={{color: colors.white , textAlign: 'right'} }>{title}</h2> */}
                        <p style={{color: colors.white , textAlign: 'right'}}>
                          {description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

  );
}
