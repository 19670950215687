import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import aneenLogo from "../images/AneenWithBg.jpg";
import classes from "./VideoPlayer.module.css";
import ReactPlayer from "react-player";
import { useData } from "../context/DataContext";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore'; // Import getDocs and collection
import { Helmet } from 'react-helmet';

// import NavbarVideo from './NavbarVideo';
const VideoPlayer = () => {
  const { videoId } = useParams();
  const data = useData();
  const [isLoading, setIsLoading] = useState(false);
  const [videoURL, setVideoURL] = useState(
    // 'https://www.youtube.com/watch?v=veuzmx1ak7g'
    null
  );
  const [videoTitle, setVideoTitle] = useState("null");
  const [videoCategory, setVideoCategory] = useState("null");
  const [videoSubcategory, setVideoSubcategory] = useState("null");
  const [videoViews, setVideoViews] = useState("null");

  useEffect(() => {
    const fetchVideoData = async () => {
      setIsLoading(true);
      try {
        const response = await data.fetchPost(videoId);
        console.log(response);
        if (response && !response.is_pending) {
          setVideoTitle(response.title);
          setVideoURL(response.url);
          setVideoCategory(response.category);
          setVideoViews(response.views);

          
          // Fetch all categories and find the matched subcategory

          const categoriesSnapshot = await getDocs(collection(db, 'categories'));
          let subcategoryName = 'Unknown Subcategory';
          categoriesSnapshot.forEach(doc => {
            const subcategories = doc.data().subcategories;
            if (subcategories) {
              const subcategory = subcategories.find(
                (subcategory) => subcategory.id === response.subcategory
              );
              if (subcategory) {
                subcategoryName = subcategory.name;
              }
            }
          });
          setVideoSubcategory(subcategoryName);
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchVideoData();
  }, [videoId]);

  
  return (
    <div>
    <Helmet>
    <title> Aneen </title>
    <meta name="description" content={videoCategory} />
    <meta property="og:title" content={videoTitle} />
    <meta property="og:description" content={videoCategory} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content=  {aneenLogo}/>
    {/* <link rel="canonical" href="https://yourwebsite.com/myscreen" /> */}
  </Helmet>
      <div className={classes.logo}>
        <Link to="/" className={classes.logoInner}>
          <img
            src={aneenLogo}
            width="70px"
            alt="logo"
            style={{ borderRadius: "10px" }}
          />
        </Link>
      </div>
      <div style={{ paddingTop: "20px" }}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
              height: "100vh", // Make the box take up the entire viewport height for vertical centering
            }}
          >
            <CircularProgress style={{ color: "#C83C2C" }} />
          </Box>
        ) : (
          <div className={classes.mediaPlayer}>
            <div className={classes.videoContainer}>
              {videoURL == null ? (
                <div className={classes.videoWrapper}>
                  <div className={classes.contentError}>الفيديو غير موجود</div>
                </div>
              ) : videoURL.includes("youtube.com") ? (
                <div className={classes.videoWrapper}>
                  <ReactPlayer
                    url={videoURL}
                    controls={true}
                    className={classes.roundedPlayer}
                    width="100%"
                    height="100%"
                  />
                </div>
              ) : (
                <div className={classes.videoWrapper}>
                  <video
                    controls
                    width="100%"
                    height="100%"
                    className={classes.roundedPlayer}
                  >
                    <source src={videoURL} type="video/mp4" />
                    <source src={videoURL} type="video/quicktime" />
                    <source src={videoURL} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </div>
            {videoURL !== null ? (
              <div className={classes.content}>
                <div className={classes.contentHeading}>{videoTitle}</div>
                <div className={classes.contentInner}>
                  <div>{videoSubcategory}</div>
                  <div>{videoCategory}</div>
                  <div>{videoViews} :مشاهدات</div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      {/* <div className={classes.videoContainer}>
        {videoURL && (
          <div className={classes.videoPlayerStyle}>
            {videoURL.includes('youtube.com') ? (
              <ReactPlayer
                url={videoURL}
                controls={true}
                className={classes.videoPlayerStyle}
                width="100vw"
                height="70vh"
              />
            ) : (
              <video controls width="100%" height="70vh">
                <source src={videoURL} type="video/mp4" />
                <source src={videoURL} type="video/quicktime" />
                <source src={videoURL} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {videoName && (
              <div className={classes.videoNameStyle}>
                Video Name: {videoName}
              </div>
            )}
          </div>
        )}
      </div> */}
    </div>
  );
};

export default VideoPlayer;
