import React from "react";
import styles from "./PrivacyPolicy.module.css";
import { useLocation } from "react-router-dom";


const PrivacyPolicy = () => {
  const content = useLocation().state;
  const spacedContent = content?.split("\n").map((str) => <li>{str}</li>);
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Privacy Policy</h1>
      <ul className={styles.text}>
        <li>
          We value your privacy and strive to protect your personal information
          in accordance with all relevant data protection laws and regulations.
        </li>
        <li>
          The data we collect: your name, email, video data, time of uploading,
          its source whether it's a camera, file or YouTube link.
        </li>
        <li>
          The purpose of collecting data: To provide the necessary support to
          our users and improve the service to you.
        </li>
        <li>
          We do not share your personal information with companies,
          organizations or individuals outside the application except in the
          following two cases:
        </li>
        <li>If we get written consent from you.</li>
        <br />
        <li>
          If we believe that access, use, retention, or disclosure of the
          information is necessary to fulfill any applicable law, regulation,
          legal process, or enforceable government request.
        </li>
        <li>
          Your rights: You have the right to access, update and delete your
          personal information at any time by logging into your account.
        </li>
        <li>
          You also have the right to object to our use of your personal
          information and to restrict our handling of it.
        </li>
        <li>
          Change policy: We may change our policy from time to time to reflect
          the change in laws of data collection and use. Please review this
          policy periodically to stay informed of any changes.
        </li>
      </ul>
      <ul className={styles.text}>{spacedContent}</ul>
      <p className={styles.contact}>
        If you have any questions or inquiries regarding the privacy policy, contact us:
        <br />
        <a href="mailto:youraneen@gmail.com" className={styles.email}>
          youraneen@gmail.com
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
